
import Vue from 'vue';
import jwt_decode from "jwt-decode";
import { debounce } from "throttle-debounce";

import DatepickerFormatted from '@/components/common/inputs/DatepickerFormatted.vue';
import UniqueEmailDialog from '@/components/common/UniqueEmailDialog.vue';
import UniqueUsernameDialog from '@/components/common/UniqueUsernameDialog.vue';
import FooterText from '@/components/common/FooterText.vue';
import PasswordRules from '@/components/common/PasswordRules.vue';
import InternationalPhoneNumberInput from '@/components/common/inputs/InternationalPhoneNumberInput.vue';

import validateEmail from '@/helpers/validate-email';
import passwordValidators from '@/helpers/validate-password';
import { isJunior, isUnder16 } from '@/helpers/validate-junior-age';

import authenticationService from "../../services/authenticationService";

const createAccountImage = require('@/assets/create-account.png');

export default Vue.extend({
  components: { FooterText, DatepickerFormatted, UniqueEmailDialog, UniqueUsernameDialog, PasswordRules, InternationalPhoneNumberInput },
  name: 'Signup',
  data() {
    return {
      createAccountImage,

      isValid: true,
      isError: false,
      isProcessing: false,
      errorMessage: '',

      recoverableEmail: false,
      showNonUniqueEmail: false,
      showNonUniqueUsername: false,

      showResults: [] as any[],

      dialog: false,

      firstName: '',
      lastName: '',
      username: '',
      password1: '',
      password2: '',
      email: '',
      validEmail: null,
      phone: '',
      isJunior: false,
      birthDate: new Date(),
      passwordRules: passwordValidators,
    };
  },
  methods: {
    processSignup: async function (res: any) {
      const decoded: any = jwt_decode(res.token);
      const event = new CustomEvent("userLoaded", {
          detail: { personId: decoded.nameid, token: res.token },
      });
      window.dispatchEvent(event);

      localStorage.setItem("userToken", res.token);
    },
    checkEmail: async function(newEmail?: string) {
      if (!!newEmail) this.email = newEmail;

      const response = await authenticationService.uniqueEmail(this.email);

      this.showNonUniqueEmail = !response.unique;
      this.recoverableEmail = !!response.recoverable;

      return response.unique;
    },
    checkUsername: async function(newUsername?: string) {
      if (!!newUsername) this.username = newUsername;

      const uniqueUsername = await authenticationService.uniqueUsername(this.username);

      this.showNonUniqueUsername = !uniqueUsername;

      return uniqueUsername;
    },
    submit: async function() {
      this.isProcessing = true;

      if (!validateEmail(this.email)) {
        this.isProcessing = false;
        (this.validEmail as any) = false;
        return;
      }

      const uniqueUsername = await this.checkUsername();
      let uniqueEmail = false;

      if (uniqueUsername) {
        uniqueEmail = await this.checkEmail();
      }

      if (uniqueUsername && uniqueEmail) {
        const response = await authenticationService.createAccount({
          firstName: this.firstName,
          lastName: this.lastName,
          username: this.username,
          password1: this.password1,
          password2: this.password2,
          email: this.email,
          phone: this.phone,
          isJunior: this.isJunior,
          birthDate: this.birthDate,
        });

        if (response.errors != null) {
          this.isProcessing = false;
          this.isError = true;
          this.errorMessage = response.errors ? response.errors.join(',') : 'An Unknown Error Occured';
        } else if (this.isJunior && isUnder16(this.birthDate)) {
          this.isProcessing = false;

          this.$store.commit('changeParentVerification', { juniorId: response.personId, firstName: this.firstName, lastName: this.lastName });
          this.$router.push('/parent-member');
        } else {
          await this.processSignup(response);

          this.isProcessing = false;

          this.$store.commit('changeAgreementFor', 'NewAccount');
          this.$router.push('/agreements');
        }
      }

      this.isProcessing = false;
    },
    validateIsJunior(v: string) {
      return (
        (!!v && isJunior(new Date(v)))
        || 'Must be under 18 years old'
      )
    },
    canSubmit() {
      return this.isValid && this.validEmail && !this.isProcessing && (this.isJunior ? !!this.birthDate : true)
    },
    updateDates: function (val: any, key: string, item: any) {
      this.birthDate = val.date;
      //workaround to force rerendering after setting up formatted date
      this.$set(this, "showResults", this.showResults);
    },
    onRecoverEmail() {
      this.$store.commit('changeRecoverInfo', {
        email: this.email,
        username: this.username,
        password: this.password1,
        confirmPassword: this.password2,
      });
      this.$router.push('/recover-account');
    }
  },
  watch: {
    email: {
      handler: debounce(
        500,
        function (this: any, newVal: string, oldVal: string) {
          if (newVal === oldVal || !newVal) return;

          if (!newVal) this.validEmail = null;
          else this.validEmail = validateEmail(newVal);
        }
      ),
      deep: true,
    },
  }
});
