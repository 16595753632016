import { api } from "./api";

export const login = async (request: any): Promise<any> => {
  try {
    const { data } = await api.post<object>(`/Authentication`, request);
    return data;
  } catch (err) {
    return err;
  }
};
export const refresh = async (): Promise<any> => {
  try {
    const { data } = await api.post<object>(`/Authentication/refresh`);
    return data;
  } catch (err) {
    console.error("error", err);
    return err;
  }
};

export const uniqueEmail = async (email: string): Promise<{ unique: boolean, recoverable?: boolean }> => {
  try {
    const { data } = await api.post<object>('/Authentication/unique-email', { email });
    return data as any;
  } catch (err) {
    console.error("error", err);
    return err as any;
  }
};

export const uniqueUsername = async (username: string): Promise<any> => {
  try {
    const { data } = await api.post<object>('/Authentication/unique-username', { username });
    const { unique  } = data as any;

    return unique;
  } catch (err) {
    console.error("error", err);
    return err;
  }
};

interface NewAccountForMember {
  personId: number | null,
  username: string,
  password1: string,
  password2: string,
  phone: string,
}

export const createAccountForMember = async (newAccount: NewAccountForMember) => {
  try {
    const { data } = await api.post<object>('/Authentication/signupMember', newAccount);
    return data as any;
  } catch (err) {
    console.error("error", err);

    if ((err as any)?.status === 400) {
      return { errors: (err as any).data.errors };
    }

    return err;
  }
};

interface NewAccount {
  firstName: string,
  lastName: string,
  username: string,
  password1: string,
  password2: string,
  email: string,
  phone: string,
  isJunior: boolean,
  birthDate?: Date,
}

export const createAccount = async (newAccount: NewAccount) => {
  try {
    const { data } = await api.post<object>('/Authentication/signup', newAccount);
    return data as any;
  } catch (err) {
    console.error("error", err);

    if ((err as any)?.status === 400) {
      return { errors: (err as any).data.errors };
    }

    return err;
  }
};

export const verifyMembership = async (memberId: string, firstName: string, lastName: string): Promise<any> => {
  try {
      const { data } = await api.post<any>(`/Authentication/verify-member`, { memberId, firstName, lastName });
      return {...data, success: true};
  } catch (err) {
    console.error("error", err);

    if ((err as any)?.status === 400) {
      return { errors: (err as any).data.errors };
    }

    return err;
  }
}

export const verifyUsername = async (memberId: string, email: string, firstName: string, lastName: string): Promise<any> => {
  try {
      const { data } = await api.post<any>(`/Authentication/verify-username`, { memberId, email, firstName, lastName });
      return {...data, success: true};
  } catch (err) {
    console.error("error", err);

    if ((err as any)?.status === 400) {
      return { errors: (err as any).data.errors };
    }

    return err;
  }
}

interface RecoverAccount {
  email: string,
  username: string,
  password1: string,
  password2: string,
  verifiedEmail: boolean,
}

export const recoverAccount = async (recoverAccount: RecoverAccount) => {
  try {
    const { data } = await api.post<object>('/Authentication/recover-account', recoverAccount);
    return data as any;
  } catch (err) {
    console.error("error", err);

    if ((err as any)?.status === 400) {
      return { errors: (err as any).data.errors };
    }

    return err;
  }
};

interface ReviewData {
  email: string,
  memberId: string,
  juniorId: number,
  firstName: string,
  lastName: string,
}

export const sendActivationRequest = async (body: ReviewData) => {
  try {
    const { data } = await api.post<object>('/Authentication/review-junior', { ...body });
    return data as any;
  } catch (err) {
    console.error("error", err);

    if ((err as any)?.status === 400) {
      return { errors: (err as any).data.errors };
    }

    return err;
  }
};

interface ActivateData {
  parentId: number | null;
  juniorId: number;
  verifiedEmail: boolean;
  verifiedSMS: boolean;
  useEmail: boolean;
  useSMS: boolean;
  agreedToPrivacyPolicy?: boolean;
  agreedToDataSharingPolicy?: boolean;
}

export const activateJunior = async (body: ActivateData) => {
  try {
    const { data } = await api.post<object>('/Authentication/activate-junior', { ...body });
    return data as any;
  } catch (err) {
    console.error("error", err);

    if ((err as any)?.status === 400) {
      return { errors: (err as any).data.errors };
    }

    return err;
  }
};

export const getBasicJuniorData = async (juniorId: any) => {
  try {
    const { data } = await api.get(`/Authentication/junior/${juniorId}`);
    return data as any;
  } catch (err) {
    console.error("error", err);
    return err;
  }
}

export default {
  login,
  refresh,
  uniqueEmail,
  uniqueUsername,
  createAccount,
  createAccountForMember,
  verifyMembership,
  verifyUsername,
  recoverAccount,
  sendActivationRequest,
  activateJunior,
  getBasicJuniorData,
};