
import Vue from 'vue';
import FooterText from '@/components/common/FooterText.vue';
import userService from '@/services/portal/userService';

const logoImage = require('@/assets/logo.svg');

export default Vue.extend({
    components: { FooterText },
    name: 'LinkOrRegister',
    data() {
        const agreementFor = this.$store.state.agreementFor;

        let title;

        switch (agreementFor) {
            case "New Account":
                title = "Thank you for creating your ASCA web account."
                break;
            default:
                title = "Gain more perks with an ASCA Membership!"
                break;
        }

        return { title, logoImage };
    },
    methods: {
        async onSkip() {
            try {
                await userService.setPromptedForMembershipAt()
            } catch (error) {
                console.log(error.message);
            }

            this.$router.push('/');
        },
        async onRegister() {
            try {
                await userService.setPromptedForMembershipAt()
            } catch (error) {
                console.log(error.message);
            }

            this.$router.push('/registration/member');
        }
    }
});
