
import Vue from 'vue';
import FooterText from '@/components/common/FooterText.vue';
import VerifyCodeDialog from '@/components/common/VerifyCodeDialog.vue';
import authenticationService from "@/services/authenticationService";
import { VerificationType } from "@/services/codeVerificationService";
import { UserInfo } from "@/types/User";

const logoImage = require('@/assets/logo.svg');
const createAccountImage = require('@/assets/create-account.png');

export default Vue.extend({
  components: { FooterText, VerifyCodeDialog },
  name: 'LinkUserToMembershipByCode',
  data() {
    return {
      createAccountImage,
      logoImage,
      isProcessing: false,
      errorMessage: '',
      showAuthorizeCodeModal: false,
      isValid: false,
      memberId: '',
      firstName: '',
      lastName: '',
      emailOnFile: '',
    };
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    emailVerificationType: function () {
      return VerificationType.Email;
    }
  },
  created: function() {  
      localStorage.removeItem("membershipLinkPersonId");
      localStorage.removeItem("membershipLinkIsJunior");
      localStorage.removeItem("membershipLinkJuniorBirthdate");
  },
  methods: {
    validateMemberData: async function() {
        this.isProcessing = true;

        const response = await authenticationService.verifyMembership(this.memberId, this.firstName, this.lastName);

        if(!!response.id && !!response.email) {
          this.emailOnFile = response.email;
          this.errorMessage = '';
          this.isProcessing = false;
          this.showAuthorizeCodeModal = true;
          
          localStorage.setItem("membershipLinkPersonId", response.id);

          if(!!response.isJunior){
            localStorage.setItem("membershipLinkIsJunior", response.isJunior);
            localStorage.setItem("membershipLinkJuniorBirthdate", response.juniorBirthDate);
          }
        } else {
          this.errorMessage = !!response.messages ? response.messages.join(', ') : '';
          this.isProcessing = false;
        }
    },
    processMembershipLinking: async function() {
      this.$router.push('/signupMember');
    },
  },
});
