import { RouteConfig } from "vue-router";

// Public
import Agreements from "../views/Public/Agreements.vue";
import LoggedOut from "../views/Public/LoggedOut.vue";
import Login from "../views/Public/Login.vue";
import ForgotPassword from "../views/Public/ForgotPassword.vue";
import ForgotUsername from "../views/Public/ForgotUsername.vue";
import Signup from "../views/Public/Signup.vue";
import SignupMember from "../views/Public/SignupMember.vue";
import LinkUserToMembershipByCode from "../views/Public/LinkUserToMembershipByCode.vue";
import ParentMember from "../views/Public/Junior/ParentMember.vue";
import ParentVerification from "../views/Public/Junior/ParentVerification.vue";
import ActivatedJunior from "../views/Public/Junior/ActivatedJunior.vue";
import RecoverAccount from "../views/Public/RecoverAccount.vue";

const routes: Array<RouteConfig> = [
    {
      path: '/agreements',
      name: 'Agreements',
      component: Agreements,
      meta: {layout: 'empty'}
    },
    {
      path: '/signup',
      name: 'Signup',
      component: Signup,
      meta: {layout: 'empty'}
    },
    {
      path: '/signupMember',
      name: 'SignupMember',
      component: SignupMember,
      meta: {layout: 'empty'}
    },
    
    {
      path: '/link-user-membership',
      name: 'LinkUserToMembershipByCode',
      component: LinkUserToMembershipByCode,
      meta: {layout: 'empty'}
    },
    {
      path: '/recover-account',
      name: 'RecoverAccount',
      component: RecoverAccount,
      meta: {layout: 'empty'}
    },
    {
      path: '/verification/success',
      name: 'ActivatedJunior',
      component: ActivatedJunior,
      meta: {layout: 'empty'}
    },
    {
      path: '/parent-member',
      name: 'ParentMember',
      component: ParentMember,
      meta: {layout: 'empty'}
    },
    {
      path: '/parent-verification',
      name: 'ParentVerification',
      component: ParentVerification,
      meta: {layout: 'empty'}
    },
    {
      path: '/logout',
      name: 'LoggedOut',
      meta: {layout: 'empty'},
      component: LoggedOut,
    },
    {
      path: '/login',
      name: 'Login',
      meta: {layout: 'empty'},
      component: Login,
    },
    {
      path: '/forgotpassword',
      name: 'ForgotPassword',
      meta: {layout: 'empty'},
      component: ForgotPassword,
    },
    {
      path: '/forgotusername',
      name: 'ForgotUsername',
      meta: {layout: 'empty'},
      component: ForgotUsername,
    },
];

export default routes.map(route => {
  return { ...route, meta: {...route.meta, isPublic: true } };
});