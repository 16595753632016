
import Vue from 'vue';
import FooterText from '@/components/common/FooterText.vue';
import VerifyCodeDialog from '@/components/common/VerifyCodeDialog.vue';
import authenticationService from "@/services/authenticationService";
import { VerificationType } from "@/services/codeVerificationService";
import { UserInfo } from "@/types/User";

const logoImage = require('@/assets/logo.svg');

export default Vue.extend({
  components: { FooterText, VerifyCodeDialog },
  name: 'LinkUserToMembershipByCode',
  data() {
    return {
      logoImage,
      isProcessing: false,
      showUsername: false,  
      errorMessage: '',
      showAuthorizeCodeModal: false,
      isValid: false,
      memberId: '',
      email: '',
      firstName: '',
      lastName: '',
      emailOnFile: '',
      username: '',  
    };
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    emailVerificationType: function () {
      return VerificationType.Email;
    }
  },
  created: function() {  
    localStorage.removeItem("membershipLinkPersonId");
  },
  methods: {
    verifyUsername: async function() {
        this.isProcessing = true;

        const response = await authenticationService.verifyUsername(this.memberId, this.email, this.firstName, this.lastName);
        
        if(!!response.username && !!response.email) {
          this.emailOnFile = response.email;
          this.errorMessage = '';
          this.isProcessing = false;
          this.showAuthorizeCodeModal = true;
          
          this.username = response.username;
        } else {
          this.errorMessage = !!response.messages ? response.messages.join(', ') : '';
          this.isProcessing = false;
          this.showUsername = false;
        }
    },    
    displayUsername: async function() {
      this.showAuthorizeCodeModal = false;
      this.showUsername = true;
    },
  },
});
