
import Vue from 'vue';
import jwt_decode from "jwt-decode";

import DatepickerFormatted from '@/components/common/inputs/DatepickerFormatted.vue';
import UniqueUsernameDialog from '@/components/common/UniqueUsernameDialog.vue';
import FooterText from '@/components/common/FooterText.vue';
import PasswordRules from '@/components/common/PasswordRules.vue';
import InternationalPhoneNumberInput from '@/components/common/inputs/InternationalPhoneNumberInput.vue';

import passwordValidators from '@/helpers/validate-password';
import { isJunior, isUnder16 } from '@/helpers/validate-junior-age';

import authenticationService from "../../services/authenticationService";

const createAccountImage = require('@/assets/create-account.png');

export default Vue.extend({
  components: { FooterText, DatepickerFormatted, UniqueUsernameDialog, PasswordRules, InternationalPhoneNumberInput },
  name: 'SignupMember',
  data() {
    return {
      createAccountImage,

      isValid: false,
      isError: false,
      isProcessing: false,
      errorMessage: '',

      showNonUniqueUsername: false,

      showResults: [] as any[],

      dialog: false,

      username: '',
      password1: '',
      password2: '',
      phone: '',
      isJunior: false,
      birthDate: new Date(),
      passwordRules: passwordValidators,

      membershipPersonId: null
    };
  },
  created: function(){
    this.$data.membershipPersonId = localStorage.getItem("membershipLinkPersonId");
    const isJunior = localStorage.getItem("membershipLinkIsJunior");
    const birthDate = localStorage.getItem("membershipLinkJuniorBirthdate");
    if(isJunior){
      this.$data.isJunior = isJunior;
      this.$data.birthDate = birthDate;
    }
  },
  methods: {
    processSignup: async function (res: any) {
      const decoded: any = jwt_decode(res.token);
      const event = new CustomEvent("userLoaded", {
          detail: { personId: decoded.nameid, token: res.token },
      });
      window.dispatchEvent(event);

      localStorage.removeItem("membershipLinkPersonId");
      localStorage.removeItem("membershipLinkIsJunior");
      localStorage.removeItem("membershipLinkJuniorBirthdate");
      localStorage.setItem("userToken", res.token);
    },
    checkUsername: async function(newUsername?: string) {
      if (!!newUsername) this.username = newUsername;

      const uniqueUsername = await authenticationService.uniqueUsername(this.username);

      this.showNonUniqueUsername = !uniqueUsername;

      return uniqueUsername;
    },
    submit: async function() {
      this.isProcessing = true;

      const uniqueUsername = await this.checkUsername();

      if (uniqueUsername) {
        const response = await authenticationService.createAccountForMember({
          personId: this.membershipPersonId,
          username: this.username,
          password1: this.password1,
          password2: this.password2,
          phone: this.phone,
        });

        if (response.errors != null) {
          this.isProcessing = false;
          this.isError = true;
          this.errorMessage = response.errors ? response.errors.join(',') : 'An Unknown Error Occured';
        } else if (this.isJunior && isUnder16(this.birthDate)) {
          this.isProcessing = false;

          this.$store.commit('changeParentVerification', { juniorId: response.personId, firstName: '', lastName: '' });
          this.$router.push('/parent-member');
        } else {
          await this.processSignup(response);

          this.isProcessing = false;

          this.$store.commit('changeAgreementFor', 'NewAccount');
          this.$router.push('/agreements');
        }
      }

      this.isProcessing = false;
    },
    validateIsJunior(v: string) {
      return (
        (!!v && (!!this.membershipPersonId || isJunior(new Date(v))))
        || 'Must be under 18 years old'
      )
    },
    canSubmit() {
      return this.isValid && !this.isProcessing && (this.isJunior ? !!this.birthDate : true)
    },
    updateDates: function (val: any, key: string, item: any) {
      this.birthDate = val.date;
      //workaround to force rerendering after setting up formatted date
      this.$set(this, "birthDate", this.birthDate);
    },
  },
});
